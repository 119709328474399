import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "canvas",
    "legend",
    "tooltip"
  ]

  static values = {
    builder: String,
    chartType: String,
    data: Array
  }

  get #builderName() {
    return this.builderValue
  }

  get #canvas() {
    return this.canvasTarget
  }

  get #chartType() {
    return this.chartTypeValue
  }

  get #data() {
    return this.dataValue
  }

  get #legend() {
    return this.legendTarget
  }

  get #tooltip() {
    return this.tooltipTarget
  }

  async connect() {
    const chart = await import(`~/charts/builders/${this.#builderName}.js`).then(({ default: build }) => {
      return build(this.#chartType, this.#canvas, this.#legend, this.#tooltip, this.#data)
    })
  }
}
